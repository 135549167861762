import { getValidAccessToken } from '@/utils/oidc';
import { alertController, IonContent, IonFab, IonFabButton, IonFabList, IonIcon, IonImg, IonItem, IonLabel, IonList, IonPage, IonThumbnail, loadingController, onIonViewDidEnter, } from '@ionic/vue';
import { ellipsisHorizontalOutline, cogOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { getAppList, storeAppList } from '../services/db/apps';
import { queryAppList } from '../services/graphql';
import { OfflineError, UserNotLoggedInError } from '../utils/errors';
export default defineComponent({
    components: {
        IonContent,
        IonFab,
        IonFabButton,
        IonFabList,
        IonIcon,
        IonImg,
        IonItem,
        IonLabel,
        IonList,
        IonPage,
        IonThumbnail,
    },
    setup() {
        const appListRef = ref([]);
        const router = useRouter();
        onIonViewDidEnter(async () => {
            const loading = await loadingController.create({
                message: 'Lade Apps',
            });
            await loading.present();
            try {
                appListRef.value = (await getAppList()).sort((appA, appB) => appA.name.localeCompare(appB.name));
                // if (appListRef.value.length > 0)
                //     await loading.dismiss();
                await getValidAccessToken();
                const apps = (await queryAppList()).sort((appA, appB) => appA.name.localeCompare(appB.name));
                await storeAppList(apps);
                appListRef.value = apps;
                await loading.dismiss();
            }
            catch (error) {
                await loading.dismiss();
                if (error instanceof UserNotLoggedInError) {
                    console.warn('[UI] user not logged in, redirect to login page');
                    router.push('/login');
                }
                else if (error instanceof OfflineError) {
                    console.warn('client is now offline');
                }
                else {
                    console.error(error);
                    const alert = await alertController.create({
                        message: 'unbekannter Fehler',
                    });
                    await alert.present();
                }
            }
        });
        return {
            appListRef,
            cogOutline,
            ellipsisHorizontalOutline
        };
    },
});
